const dateToLiquidatorFormat = (docDate) => {
  var formattedDate, dateArray;

  if (docDate && docDate.includes('-')) {
    dateArray = docDate.split('-');
  } else if (docDate && docDate.includes('/')) {
    dateArray = docDate.split('/');
  } else {
    dateArray = [];
  }

  if (dateArray && dateArray.length == 3) {
    if (dateArray[2].length == 4) {
      formattedDate = dateArray[0] + '/' + dateArray[1] + '/' + dateArray[2];
    } else {
      formattedDate = dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0];
    }
  } else {
    formattedDate = '';
  }
  return formattedDate;
};

export default dateToLiquidatorFormat;