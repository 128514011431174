import {v4} from 'uuid'
import Reject from './Reject'
import {
  deductibleRejectCode,
  provisionLimitRejectCode
} from '../utils/constants'
import asFloatNumber from '../utils/asFloatNumber'
import moment from 'moment/moment'

export default class ProvisionDetail {
  constructor(props) {
    this.systemId = v4()
    this.id = props.id
    this.key = v4()
    this.provisionId = props.provisionId || ''
    this.provisionName = props.provisionName || ''
    this.totalAmount = props.totalAmount || 0
    this.isapreBonus = props.isapreBonus || 0
    this.otherInsurancesDiscounts = props.otherInsurancesDiscounts || 0
    this.requiredAmount = props.requiredAmount || 0
    this.requiredQuantity = props.requiredQuantity || 1
    this.approvedQuantity = props.approvedQuantity || 1
    this.prescriptionId = props.prescriptionId
    this.rejectDetails = props.rejectDetails && props.rejectDetails.length > 0 ? this.buildRejects(props.rejectDetails) : []
    this.comments = props.comments || ''
    this.noCoveredProvision = props.noCoveredProvision || false
    this.approvedValue = props.approvedValue || 0
    this.coveragePercentage = props.coveragePercentage || 0
    this.refundValue = props.refundValue || 0
    this.healthProvisionType = props.healthProvisionType || 0
    this.attentionDate = props.attentionDate || ''
    this.tariffLimitFactor = props.tariffLimitFactor || 0
    this.unitReferenceValue = props.unitReferenceValue || 0
    this.unitOriginalRequestedValue = props.unitOriginalRequestedValue || 0
    this.tooth = props.tooth
    this.sides = props.sides
    this.sides_s = props.sidesS
  }

  // Getters
  get defaultProvisionSelectValues () {
    return this.provisionId ? {
      label: this.provisionName,
      value: this.provisionId
    } : null
  }

  get showCommentsSection () {
    return this.comments !== '' || this.noCoveredProvision
  }

  get commentsLength () {
    return this.comments !== '' ? 1 : 0
  }

  get rejectsDetailsAsJson () {
    return this.rejectDetails.map((rejectDetail) => {
      return rejectDetail.asJson
    })
  }

  get asJson () {
    return {
      id: this.id,
      front_id: this.systemId,
      health_provision_type: this.healthProvisionType,
      total_value: asFloatNumber(this.totalAmount),
      primary_insurance_value: asFloatNumber(this.isapreBonus),
      other_insurance_value: asFloatNumber(this.otherInsurancesDiscounts),
      required_value_money: asFloatNumber(this.requiredAmount),
      requested_quantity: this.requiredQuantity,
      approved_quantity: this.approvedQuantity,
      provision_id: this.provisionId,
      prescription_id: this.prescriptionId,
      comment: this.comments,
      rejects: this.rejectsDetailsAsJson,
      tooth: this.tooth,
      sides: this.sides,
      attention_date: this.attentionDate
    }
  }

  get canBeEvaluated () {
    return parseFloat(this.totalAmount) !== 0 && parseFloat(this.requiredAmount) !== 0 && parseInt(this.requiredQuantity)
  }

  // Methods
  changeKey = () => {
    this.key = v4()
  }

  buildTestRejectDetails = () => {
    return [
      new Reject({
        code: provisionLimitRejectCode,
        value: 17500,
        description: 'Tope de prestación',
        active: true
      }),
      new Reject({
        code: deductibleRejectCode,
        value: 8950,
        description: 'Sujeto a deducible',
        active: false
      })
    ]
  }

  buildRejects = (rejectDetails) => {
    return rejectDetails.map((rejectDetail) => {
      return new Reject(rejectDetail)
    })
  }

  buildRejectsFromEvaluation = (rejectDetails) => {
    return rejectDetails.map((rejectDetail) => {
      let originalReject = this.rejectDetails.filter((originalRejectDetail) => {
        return originalRejectDetail.systemId === rejectDetail.front_id
      })[0]
      if (originalReject) {
        // Lo encontramos, así que lo modificaremos con los nuevos datos
        return originalReject.buildFromEvaluation(rejectDetail)
      }
      // Si no lo encontramos, quiere decir que es un nuevo rechazo
      return new Reject(rejectDetail)
    })
  }

  buildFromEvaluation = (evaluatedProvisionDetail) => {
    this.id = evaluatedProvisionDetail.id
    this.coveragePercentage = evaluatedProvisionDetail.refund_coverage || 0
    this.refundValue = evaluatedProvisionDetail.refund_value_money || 0
    this.approvedValue = evaluatedProvisionDetail.approved_value_money || 0
    this.comment = evaluatedProvisionDetail.comment || ''
    this.tariffLimitFactor = evaluatedProvisionDetail.tariffLimitFactor || 0
    this.unitReferenceValue = evaluatedProvisionDetail.unitReferenceValue || 0
    // Ahora debemos modificar los rejectDetails para el provision detail
    this.rejectDetails = evaluatedProvisionDetail.rejects && evaluatedProvisionDetail.rejects.length > 0 ? this.buildRejectsFromEvaluation(evaluatedProvisionDetail.rejects) : []
    return this
  }

}